'use client'

import { useFormContext, Controller } from 'react-hook-form'
import Input from '../../Input/Input'
import InputHidden from '../../Input/InputHidden'
import Checkbox from '../../Checkbox/Checkbox'
import Radio from '../../RadioButton/RadioButton'
import RadioGroup from '../../RadioButton/RadioGroup'
import Select from '../../Select/Select'
import Option from '../../Select/Option'
import Textarea from '../../Textarea/Textarea'
import FormControl from '../FormControl'
import FormHelperText from '../FormHelperText'
import FormLabel from '../FormLabel'
import { useFormContext as useUIFormContext } from '../FormProvider'
import fieldValidations from './utils/field-validations'
import getHiddenFieldValue from './utils/get-hidden-field-value'
import getLanguageTranslation from './utils/get-language-translation'

const HubspotFormFieldController = ({ field, dependentField, ...rest }) => {
  const {
    unregister,
    register,
    control,
    trigger,
    formState: { errors },
    getValues,
  } = useFormContext()

  const { language } = useUIFormContext()

  const handleBlurValidation = async (name, onBlur) => {
    await trigger(name) // Trigger validation for the specified input field
    if (onBlur) onBlur()
  }

  const handleUnregisterEvents = (value) => {
    // If the field has dependent fields, we need to check if they will be hidden, if so, we need to unregister them from the form
    if (field?.dependentFields) {
      field?.dependentFields.forEach((dependentField) => {
        if (
          dependentField?.dependentCondition?.operator === 'set_any' &&
          !dependentField?.dependentCondition?.values?.includes(value)
        ) {
          unregister(dependentField?.dependentField?.name)
        }
      })
    }
  }

  const fieldData = dependentField?.dependentField || field
  const fieldNameParts = fieldData.name.split('.')
  let finalError = Object.keys(errors).length > 0 ? errors : null

  for (const key of fieldNameParts) {
    if (finalError && key in finalError) {
      finalError = finalError[key]
    }
  }

  let showField = true
  const formFieldValues = getValues()

  if (dependentField?.dependentField) {
    switch (dependentField.dependentCondition.operator) {
      case 'set_any':
        showField = dependentField.dependentCondition.values.includes(formFieldValues[field.name])
        break
    }
  }

  if (!showField) {
    return
  }

  let fieldName = fieldData?.name

  if (fieldData?.objectTypeId !== '0-1') {
    fieldName = `${fieldData?.objectTypeId}/${fieldName}`
  }

  // * Check if password field and change props accordingly
  // * We have password not required in Hubspot so that they don't fail form submit when we don't send. We don't want to send password to Hubspot because it's PII
  if (fieldName === 'password') {
    fieldData.label = getLanguageTranslation('passwordLabel', language)
    fieldData.required = true
  }

  return (
    <FormControl error={Boolean(finalError?.message)} {...rest}>
      {!fieldData?.hidden && fieldData?.fieldType !== 'single_checkbox' && (
        <FormLabel htmlFor={fieldName} required={fieldData?.required}>
          {fieldData?.label}
        </FormLabel>
      )}
      <Controller
        key={fieldName}
        name={fieldName}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => {
          const commonProps = {
            id: fieldName,
            name: fieldName,
            hidden: fieldData?.hidden,
            placeholder: fieldData?.placeholder,
            defaultValue:
              fieldData.defaultValue !== undefined
                ? fieldData.defaultValue
                : fieldData.defaultValues?.length
                  ? fieldData.defaultValues[0]
                  : '',
            value,
            ...register(fieldName, fieldValidations(fieldData, language)),
          }

          const { id, defaultValue, ...restCommonProps } = commonProps

          if (fieldData.hidden) {
            const actualValue = getHiddenFieldValue(fieldData.name)
            commonProps.defaultValue = actualValue || fieldData.defaultValue
            commonProps.value = actualValue || fieldData.defaultValue

            return <InputHidden {...commonProps} type="text" />
          }

          switch (fieldData.fieldType) {
            case 'single_line_text':
            case 'email':
            case 'phone':
            case 'number':
              // eslint-disable-next-line no-case-declarations -- makes the most sense here as its not needed for other field types
              const fieldType =
                fieldName === 'password'
                  ? 'password'
                  : fieldData.fieldType === 'single_line_text'
                    ? 'text'
                    : fieldData.fieldType

              return (
                <Input
                  {...commonProps}
                  size="lg"
                  type={fieldType}
                  onChange={(e) => {
                    onChange(e)
                    handleUnregisterEvents(e?.currentTarget?.value)
                  }}
                  onBlur={() => handleBlurValidation(fieldName, onBlur)}
                  disableTracking={fieldName === 'password'}
                />
              )
            case 'multi_line_text':
              return (
                <Textarea
                  {...commonProps}
                  size="lg"
                  onChange={(e) => {
                    onChange(e)
                    handleUnregisterEvents(e?.currentTarget?.value)
                  }}
                  onBlur={() => handleBlurValidation(fieldName, onBlur)}
                  minRows={4}
                />
              )
            case 'dropdown':
              return (
                <Select
                  {...commonProps}
                  size="lg"
                  name={fieldName}
                  onChange={(e, currentValue) => {
                    onChange(e)
                    handleUnregisterEvents(currentValue)
                    handleBlurValidation(fieldName, onBlur)
                  }}
                >
                  <Option value="" disabled>
                    {fieldData.placeholder || 'Please Select'}
                  </Option>
                  {fieldData?.options?.map((option) => {
                    return (
                      <Option key={option.label} value={option.value}>
                        {option.label}
                      </Option>
                    )
                  })}
                </Select>
              )
            case 'single_checkbox':
              return (
                <Checkbox
                  {...commonProps}
                  // Checkbox is always lg except for legal consent fields
                  size={fieldData.name.split('.')[0] === 'LEGAL_CONSENT' ? 'sm' : 'lg'}
                  label={fieldData.label}
                  defaultChecked={fieldData.defaultValue === 'true'}
                  onChange={(e) => {
                    onChange(e)
                    handleUnregisterEvents(e?.currentTarget?.checked)
                    // Only doing onChange for checkboxes because it makes more sense on the user end
                    handleBlurValidation(fieldName, onBlur)
                  }}
                />
              )
            case 'radio':
              return (
                <RadioGroup id={id} name={restCommonProps.name} defaultValue={defaultValue}>
                  {fieldData?.options?.map((currentOption) => {
                    return (
                      <Radio
                        // Have to spread the name, register(), etc on each radio specifically for it to register properly
                        {...restCommonProps}
                        key={currentOption?.label}
                        size="md"
                        label={currentOption?.label}
                        value={currentOption?.value}
                        defaultChecked={fieldData?.defaultValues?.includes(currentOption?.value)}
                        onChange={(e) => {
                          onChange(e)
                          handleUnregisterEvents(e?.currentTarget?.value)
                          // Only doing onChange for radio buttons because it makes more sense on the user end
                          handleBlurValidation(fieldName, onBlur)
                        }}
                      />
                    )
                  })}
                </RadioGroup>
              )
          }
        }}
      />
      {Boolean(fieldData?.description) && <FormHelperText>{fieldData.description}</FormHelperText>}
      {!fieldData?.hidden && Boolean(finalError?.message) && (
        <FormHelperText>
          {finalError && fieldData?.fieldType === 'single_checkbox'
            ? getLanguageTranslation('fieldRequired', language)
            : finalError?.message}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default HubspotFormFieldController
